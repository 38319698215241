import React from 'react';
import i18next from 'i18next';
import { Row, Col, Card, CardBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import Spinner from '../shared/spinner';
import BackOfficeSerivce from './../../services/backOffice.service';
import Flatpickr from 'react-flatpickr';
import UtilsService from './../../services/utils/utils.service';
import NoData from '../shared/noData';
import { If } from 'react-if';
import HoldingStock from './HoldingStock';
import HoldingInstrument from './holdingInstrument';
import Currency from './../shared/currency';
import HoldingStore from './holdingFilters.store';
import TermsAndConditionsFooter from '../terms-and-conditions/footer';
import axios from "axios";
import CoreService from '../../services/utils/core.service';
import * as moment from 'moment';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

export const BalanceTotal = ({ ...props }) => {
    if (!props.totals || !props.totals.length)
        return null;

    return (
        <div className={`${props.className || ''} d-inline-block ml-2 ${props.totals.length > 1 ? 'align-top' : ''}`}>
            {
                props.label ?
                    <label className="mb-0 text-bold">{i18next.t(props.label)}: </label> :
                    null
            }
            {
                props.totals.map((t, i) => {
                    let colorClass = (t.amount > 0) ? 'text-success' : (t.amount < 0) ? 'text-danger' : '';
                    return (
                        <div key={i} className={`text-bold lh-1 text-right ${colorClass}`}>
                            <Currency amount={t.amount} symbol={UtilsService.translateCurrencySymbol(t.symbol)} />
                        </div>
                    )
                })
            }
        </div>
    );
}

class Holdings extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            showBackOfficeError: false,
            showExportError: false,
            showExportQueueMessage: false,

            data: {
                agentStock: [],
                totals: [],
                agents: [],
                accounts: [],
                contracts: []
            }
        };
    }

    componentDidMount() {
        let url = this.props.router.location.pathname || '';

        if (url.indexOf('agent') >= 0)
            this.onViewChange('STOCK');
        else if (url.indexOf('stock') >= 0)
            this.onViewChange('INSTRUMENT');

        _getItems.bind(this)();
    }

    onDateChange = (date) => {
        HoldingStore.setFilter({
            date: (date && date.length) ? date[0] : null,
            agent: '',
            account: '',
            instrument: '',
        })

        _getItems.bind(this)();
    }

    onViewChange = (view) => {
        if (HoldingStore.view != view) {
            HoldingStore.view = view;
            let urlParam = (view === 'STOCK') ? 'agent' : 'stock';

            this.props.router.navigate(`/portfolio/${urlParam}`);

            _getItems.bind(this)();
        }
    }

    clearFilters = () => {
        HoldingStore.clearFilters();
        _getItems.bind(this)();
    }

    _getMinDate = () => {
        /* let d = new Date();
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0); 

        return d; */

        return moment().startOf('day').subtract(30, 'days').toDate();
    }

    /**
     * Export to Excel
     */
    exportToExcel = () => {
        let type = '';
        if(HoldingStore.view == 'STOCK') {
            type = `stock-export`;
        } else {
            type = `instrument-export`;
        }

        let params = UtilsService.deepCopy(HoldingStore.filter);
        let url = `${window.apiUrl}/api/BackOffice/holdings/${type}`;
        url = UtilsService.addParamsToUrl(url, params);

        // Axios config
        let config = CoreService.getAxiosConfig();

        // Show loading
        this.setState({isLoading: true});

        // Axios request
        axios
            .get(encodeURI(url), config)

            .then(response => {
                var r = response.data;

                if(r.httpCode == 200 && r.data) {
                    // Check response type
                    if(r.data.type == 'real-time') {
                        // save file
                        window.location.href = r.data.file.url;
                    } else if(r.data.type == 'queue') {
                        // Show message
                        this.setState({showExportQueueMessage: true});
                    } else {
                        // Error
                        this.setState({showExportError: true});
                    }
                } else {
                    this.setState({showExportError: true});
                }
            })

            .catch((error) => this.setState({showExportError: true}))

            .finally(() => this.setState({isLoading: false}))
        ;
    }

    hideExportQueueMessage = () => {
        this.setState({showExportQueueMessage: false});
    }

    render() {
        if (!!this.state.showBackOfficeError) {
            return (
                <NoData
                    className="mt-5"
                    imgSrc="/assets/img/no-internet.svg"
                    msg={`${i18next.t('CANT_SHOW_DATA_ATM')}`} />
            );
        }

        return (
            <React.Fragment>
                <div className="holdings">
                    <Card className="w-100 border-radius-0">
                        <CardBody className="py-3">
                            <Row className="align-items-center">
                                <Col md="6">
                                    <h5 className="m-0 text-bold text-muted d-inline-block">{i18next.t('PORTFOLIO')}</h5>
                                </Col>
                                <Col md="6" className="text-right">
                                    <span className="mr-2">{i18next.t('SELECT_VIEW')}: </span>
                                    <UncontrolledDropdown className="swicth-view-dropdown">
                                        <DropdownToggle caret color="primary" size="sm" className={`btn-outline-primary ${HoldingStore.view.toLocaleLowerCase()}`}>
                                            {i18next.t(HoldingStore.view == 'INSTRUMENT' ? 'INSTRUMENT_VIEW' : 'STOCK_VIEW')}
                                        </DropdownToggle>
                                        <DropdownMenu end>
                                            <DropdownItem
                                                className={HoldingStore.view == 'INSTRUMENT' ? 'active instrument' : null}
                                                onClick={() => this.onViewChange('INSTRUMENT')}>{i18next.t('INSTRUMENT_VIEW')}</DropdownItem>
                                            <DropdownItem
                                                className={HoldingStore.view == 'STOCK' ? 'active stock' : null}
                                                onClick={() => this.onViewChange('STOCK')}>{i18next.t('STOCK_VIEW')}</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <div className="main-content container-fluid pb-5">
                        <Card>
                            <CardBody>

                                {HoldingStore.isSomeFilter ?
                                    <div className="clear-filters">
                                        <Button
                                            type="button"
                                            color="link"
                                            onClick={this.clearFilters}>
                                            {i18next.t('CLEAR_FILTERS')}
                                        </Button>
                                    </div> :
                                    null
                                }
                                <Row className="mb-4">
                                    <Col md="3">
                                        <label htmlFor="title">
                                            <i className="fa fa-calendar mr-1"></i>
                                            {i18next.t('DATE')}:
                                        </label>
                                        <Flatpickr
                                            className="form-control bg-white"
                                            value={HoldingStore.filter.date}
                                            options={{
                                                dateFormat: "d/m/Y",
                                                maxDate: _getMaxDate(),
                                                locale: UtilsService.getFlatpickrLocale(),
                                                minDate: this._getMinDate(),
                                                //clickOpens: false
                                            }}
                                            onChange={this.onDateChange}
                                            required />
                                    </Col>
                                    <Col md={{ size: 8, offset: 1 }}>
                                        <Row>
                                            <If condition={!!this.state.data.agents.length}>
                                                <Col xs="4">
                                                    <label>{i18next.t('AGENT')}:</label>
                                                    <select className="form-control"
                                                        value={HoldingStore.filter.agent}
                                                        onChange={(e) => { HoldingStore.setFilterProp('agent', e.target.value); _getItems.bind(this)() }}
                                                    >
                                                        <option value=''>-{i18next.t('ALL')}-</option>
                                                        {this.state.data.agents.map((item, index) =>
                                                            <option key={index} value={item}>{item}</option>
                                                        )}
                                                    </select>
                                                </Col>
                                            </If>
                                            <If condition={!!this.state.data.accounts.length}>
                                                <Col xs="4">
                                                    <label>{i18next.t('ACCOUNT')}:</label>
                                                    <select className="form-control"
                                                        value={HoldingStore.filter.account}
                                                        onChange={(e) => { HoldingStore.setFilterProp('account', e.target.value); _getItems.bind(this)() }}
                                                    >
                                                        <option value=''>-{i18next.t('ALL')}-</option>
                                                        {this.state.data.accounts.map((item, index) =>
                                                            <option key={index} value={item.code}>{item.displayText}</option>
                                                        )}
                                                    </select>
                                                </Col>
                                            </If>
                                            <If condition={!!this.state.data.contracts.length}>
                                                <Col xs="4">
                                                    <label>{i18next.t('INSTRUMENT')}:</label>
                                                    <select className="form-control"
                                                        value={HoldingStore.filter.instrument}
                                                        onChange={(e) => { HoldingStore.setFilterProp('instrument', e.target.value); _getItems.bind(this)() }}
                                                    >
                                                        <option value=''>-{i18next.t('ALL')}-</option>
                                                        {this.state.data.contracts.map((item, index) =>
                                                            <option key={index} value={item}>{item}</option>
                                                        )}
                                                    </select>
                                                </Col>
                                            </If>
                                        </Row>
                                    </Col>
                                </Row>

                                { <div className="mb-4 text-right">
                                    <button type="button" className="btn btn-primary" onClick={this.exportToExcel}>
                                        <i className="fa fa-file-pdf-o mr-2"></i>
                                        {i18next.t('DOWNLOAD_XLSX')}
                                    </button>

                                    { this.state.showExportError ? 
                                        <p className="pt-2">
                                            {i18next.t('DOWNLOAD_XLSX_ERROR')}
                                        </p> 
                                        : null }
                                </div> }

                                <div>
                                    {
                                        !this.state.isLoading ?
                                            (
                                                HoldingStore.view == 'STOCK' ?
                                                    <HoldingStock
                                                        data={this.state.data}
                                                        filter={HoldingStore.filter}
                                                        flags={{ isLoading: this.state.isLoading, showBackOfficeError: this.state.showBackOfficeError }} /> :
                                                    <HoldingInstrument
                                                        data={this.state.data}
                                                        filter={HoldingStore.filter}
                                                        flags={{ isLoading: this.state.isLoading, showBackOfficeError: this.state.showBackOfficeError }} />
                                            )
                                            : null
                                    }

                                </div>
                            </CardBody>
                            {this.state.isLoading && <Spinner />}
                        </Card>
                        {
                            //SEUO & Termins And Conditions: Warning
                            this.state.data && (
                                (this.state.data.agentStock && this.state.data.agentStock.length) ||
                                (this.state.data.businessUnits && this.state.data.businessUnits.length)
                            ) ?
                                <TermsAndConditionsFooter /> :
                                null
                        }
                    </div>
                </div>

                { this.state.showExportQueueMessage ? 
                    <Modal isOpen={true} backdrop={true}>
                        <ModalHeader>{i18next.t('EXPORT_QUEUE')}</ModalHeader>
                        <ModalBody>
                            <div>
                                <p>{i18next.t('EXPORT_QUEUE_MESSAGE')}</p>

                                <div className="text-right d-flex align-items-center justify-content-end mr-2">
                                    <button type="button" 
                                        className="btn btn-primary btn-sm mr-2"
                                        onClick={this.hideExportQueueMessage}
                                        style={{
                                            'backgroundColor': '#2977a7',
                                            'borderColor': '#2977a7',
                                        }}>
                                        {i18next.t('OK')}
                                    </button>
                                </div> 
                            </div>
                        </ModalBody>
                    </Modal>
                    : null
                }

            </React.Fragment>
        );
    }
}

export default UtilsService.withRouter(Holdings);

function _getItems() {
    this.setState({
        isLoading: true,
        showBackOfficeError: false,
        showExportError: false
    });

    let filter = UtilsService.deepCopy(HoldingStore.filter);
    let promise = BackOfficeSerivce[HoldingStore.view == 'STOCK' ? 'getStock' : 'getHoldingInstrument'](filter);

    promise
        .then((data) => {
            this.setState({
                data: data
            })
        })
        .catch((error) => {
            if (!error || (error.errorCodes && error.errorCodes.indexOf('BACK_OFFICE_ERROR') >= 0)) {
                this.setState({showBackOfficeError: true});
            }
        })
        .finally(() => this.setState({isLoading: false}))
}

function _getMaxDate() {
    let d = new Date();
    d.setHours(23);
    d.setMinutes(59);
    d.setSeconds(0);

    return d;
}
