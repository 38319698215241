import React from 'react';
import IdentitySerivce from './../indentity.service';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import i18next from 'i18next';
import {
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";

let UtilsService = {
    deepCopy(item) {
        return item ? JSON.parse(JSON.stringify(item)) : item;
    },
    setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }

        return null;
    },
    deleteCookie(name) {
        document.cookie = name + '=; Max-Age=-99999999;';
    },
    parseJwt(token) {
        if (!token)
            return null;

        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

        return JSON.parse(window.atob(base64));
    },
    getTokenBySchema(schema, token) {
        switch (schema) {
            case 'Bearer':
                return `Bearer ${token}`;
            default:
                return token;
        }
    },
    addParamsToUrl(url, data) {
        if (data) {
            var params = [];
            for (let key in data) {
                if (key && data[key] != null && data[key] !== '')
                    params.push(`${key}=${data[key]}`);
            }

            if (params.length)
                url = `${url}?${params.join('&')}`;
        }

        return url;
    },
    getBaseUrl() {
        //let url = window.location;
        //return url.protocol + "//" + url.host;
        return null; // TODO
    },
    getDefaultLang() {
        let authData = IdentitySerivce.getAuthData();
        if (authData && authData.language)
            return authData.language;

        //let language = navigator.language || navigator.userLanguage || '';
        let language = '';
        let split = language.split('-');
        if (split && split.length && split[0].length == 2) {
            if(split[0].toLowerCase() == 'en') {
                return split[0].toLowerCase();
            }
        }

        return 'es';
    },
    getDefaultKeys(translation) {
        var keys = {};
        for (let key in translation)
            keys[key] = key;

        return keys;
    },
    toLowerCaseSafe(value) {
        return (!!value && isNaN(value)) ? value.toLowerCase() : value;
    },
    containsSafe(prop, value) {
        if (typeof prop == 'string' && typeof value == 'string')
            return prop.toLowerCase().indexOf(value.toLowerCase()) >= 0;

        return false;
    },
    dateFormat(date, format) {
        if (!date)
            return '-';

        let d = (typeof date === 'string') ? new Date(date) : date;

        let dd = `0${d.getDate()}`.slice(-2);
        let MM = `0${d.getMonth() + 1}`.slice(-2);
        let yyyy = `${d.getFullYear()}`;
        let hh = `0${d.getHours()}`.slice(-2);
        let mm = `0${d.getMinutes()}`.slice(-2);
        let ss = `0${d.getSeconds()}`.slice(-2);

        switch (format) {
            case 'dd/MM/yyyy hh:mm:ss':
                return `${dd}/${MM}/${yyyy} ${hh}:${mm}:${ss}`;

            case 'dd/MM/yyyy hh:mm':
                return `${dd}/${MM}/${yyyy} ${hh}:${mm}`;

            case 'dd/MM/yyyy':
            default:
                return `${dd}/${MM}/${yyyy}`;
        }
    },
    dateToJson(d) {

        let dd = `0${d.getDate()}`.slice(-2);
        let MM = `0${d.getMonth() + 1}`.slice(-2);
        let yyyy = `${d.getFullYear()}`;
        let hh = `0${d.getHours()}`.slice(-2);
        let mm = `0${d.getMinutes()}`.slice(-2);
        let ss = `0${d.getSeconds()}`.slice(-2);
        let ms = `00${d.getMilliseconds()}`.slice(-3);

        return `${yyyy}-${MM}-${dd}T${hh}:${mm}:${ss}.${ms}`;
    },
    jsonToDate(dateJSON) {
        let d = new Date(`${dateJSON}`);
        return d;
    },
    currency(amount = 0, decimals = 2) {
        let value = this.toFixed(amount, decimals);
        /* value = Number(value).toLocaleString(i18next.language == 'es' ? 'es' : 'en', {
            minimumFractionDigits: 0,
            maximumFractionDigits: Number(decimals)
        }); */

        let decSep = i18next.language == 'es' ? ',' : '.';
        let thouSep = i18next.language == 'es' ? '.' : ',';
        
        return this.formatMoney(value, decimals, decSep, thouSep);
    },
    cuit(cuit) {
        if (cuit) {
            let first = cuit.slice(0, 2);
            let middle = cuit.slice(2, cuit.length - 1);
            let last = cuit.slice(-1);

            return `${first}-${middle}-${last}`;
        }

        return '-'
    },
    getFlatpickrLocale() {
        if(i18next.language == 'es') {
            return Spanish;
        } 

        return 'en';
    },
    decodeURISecure(url) {
        let uri = url || '';
        
        while (uri !== decodeURIComponent(uri))
            uri = decodeURIComponent(uri);

        return uri;
    },
    encodeURISecure(url) {
        let uri = url || '';

        return encodeURIComponent(uri);
    },

    toFixed(num, fixed) {
        const re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
        let amount = this.fixNumber(num).toString().match(re)[0];

        return this.toFixedSpecial(amount, fixed);
        //return num.toString().match(re)[0];
    },

    toFixedSpecial(num, fixed) {
        var str = Number(num).toFixed(fixed);
        if (str.indexOf('e') === -1) {
          return str;
        }

        return this.fixNumber(str);
    },

    fixNumber(x) {
        if (Math.abs(x) < 1.0) {
            var e = parseInt(x.toString().split('e-')[1]);
            if (e) {
                x *= Math.pow(10,e-1);
                x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
            }
        } else {
            var e = parseInt(x.toString().split('+')[1]);
            if (e > 20) {
                e -= 20;
                x /= Math.pow(10,e);
                x += (new Array(e+1)).join('0');
            }
        }
        return x;
    },

    formatMoney(number, decimals, dec_point, thousands_sep) {
        decimals = isNaN(decimals = Math.abs(decimals)) ? 2 : decimals;
        
        dec_point = typeof dec_point === "undefined" ? "." : dec_point;
        thousands_sep = typeof thousands_sep === "undefined" ? "," : thousands_sep;

        var n = !isFinite(+number) ? 0 : number, 
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            s = (prec ? this.toFixed(n, prec) : Math.round(n)).toString().split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, thousands_sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }

        let result = s.join(dec_point);
        return result;
    },

    isNumeric(value) {
        return /^\d+$/.test(value);
    },
    
    isMobile() {
        const windowWidth = document.body.clientWidth || document.body.offsetWidth || document.documentElement.clientWidth || document.documentElement.offsetWidth;
        return windowWidth <= 768 ? true : false;
    },

    translateCurrencySymbol( currencySymbol ){
        const translation = {
            "USD G"     : "USD MTR",
            "ARS"       : "ARS",
            "USD R"     : "USD REF",
            "USD D"     : "USD MEP",
            "USD C"     : "USD C",
            "ARS MatBA" : "ARS",
            "EUR"       : "EUR",
            "U$D"       : "USD G",
            "UVA"       : "UVA",
        }
        return translation[currencySymbol] ? translation[currencySymbol] : currencySymbol
    },

    withRouter(Component) {
        function ComponentWithRouterProp(props) {
          let location = useLocation();
          let navigate = useNavigate();
          let params = useParams();

          return (
            <Component
              {...props}
              router={{ location, navigate, params }}
            />
          );
        }
      
        return ComponentWithRouterProp;
    }
};

export default UtilsService;