import React from 'react';
import i18next from 'i18next';
import { Row, Col, Card, CardBody, CardHeader, Button, Collapse } from 'reactstrap';
import NoData from '../shared/noData';
import Currency from './../shared/currency';
import { BalanceTotal } from './holdings';
import { Tooltip } from 'react-tooltip'
import UtilsService from './../../services/utils/utils.service';

export default class HoldingInstrumentTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            openedIndex: null,
            openedRowAgentIndex: []
        };
    }

    componentDidMount() { }

    toggleCollapse = (index1, index2) => {
        let index = `${index1}${index2}`;

        this.setState({
            openedIndex: (this.state.openedIndex != index) ? index : null
        });
    }

    isCollapseOpen(index1, index2) {
        let index = `${index1}${index2}`;
        return this.state.openedIndex == index;
    }

    onRowClickOpenAgent(index1, subIndex, i) {
        let index = `${index1}${subIndex}${i}`;

        let openedRowAgentIndex = this.state.openedRowAgentIndex;
        let k = openedRowAgentIndex.indexOf(index);
        if (k < 0) {
            openedRowAgentIndex.push(index);
        } else {
            openedRowAgentIndex.splice(k, 1);
        }

        this.setState({
            openedRowAgentIndex: openedRowAgentIndex
        })
    }

    isRowAgentOpened(index1, subIndex, i) {
        let index = `${index1}${subIndex}${i}`;
        return this.state.openedRowAgentIndex.indexOf(index) >= 0;
    }

    isVisible = (agents) => {
        return (agents && agents.length == 1) ?
            { color: '#e8f3f8' } :
            null;
    }

    render() {
        if (!this.props.totals.length)
            return null;

        return (
            <Card className="mb-2">
                <CardHeader
                    className="cursor-pointer"
                    onClick={() => this.toggleCollapse(this.props.index, this.props.subIndex)}
                >
                    <Row className="align-items-center">
                        <Col xs="10">
                            <span className="d-inline-block" style={{ minWidth: '150px' }} >
                                <img src={`/assets/img/${this.props.icon}`} className="mr-2" height="18" width="18" />
                                {i18next.t(this.props.titleKey)}:
                            </span>
                            <BalanceTotal
                                totals={this.props.totals}
                                tag={'strong'} />
                        </Col>
                        <Col className="text-right">
                            <Button className="m-0 p-0" color="link" size="sm">
                                <i className={`fa fa-chevron-${this.isCollapseOpen(this.props.index, this.props.subIndex) ? 'up' : 'down'}`}></i>
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <Collapse isOpen={this.isCollapseOpen(this.props.index, this.props.subIndex)}>
                    <CardBody className="p-0">
                        <div className="table-responsive" style={{ marginBottom: '-1rem' }}>
                            <table className="table table-sm border table-instruments">
                                <thead>
                                    <tr className="font-italic">
                                        <th>{i18next.t('INSTRUMENT')}</th>
                                        <th className="text-right">
                                            <span>
                                                {i18next.t('QUANTITY')}
                                            </span>
                                        </th>
                                        {this.props.isAsset ?
                                            <React.Fragment>
                                                <th className="text-right">
                                                    <span 
                                                        data-tooltip-id="price-tooltip"
                                                        data-tooltip-content={i18next.t('PRICE_TOOLTIP')}
                                                    >
                                                        {i18next.t('PRICE')}
                                                    </span>
                                                </th>
                                                <th className="text-right">
                                                    <span 
                                                        data-tooltip-id="valued-active-tooltip"
                                                        data-tooltip-content={i18next.t('VALUED_ACTIVE_TOOLTIP')}
                                                    >
                                                        {i18next.t('VALUED_ACTIVE')}
                                                    </span>
                                                </th>
                                            </React.Fragment> :
                                            <React.Fragment>
                                                <th className="text-right">
                                                    <span 
                                                        data-tooltip-id="difference-price-tooltip"
                                                        data-tooltip-content={i18next.t('DIFFERENCE_PRICE_TOOLTIP')}
                                                    >
                                                        {i18next.t('PRICE')}
                                                    </span>
                                                </th>
                                                <th className="text-right">
                                                    <span 
                                                        data-tooltip-id="difference-settlement-tooltip"
                                                        data-tooltip-content={i18next.t('DIFFERENCE_SETTLEMENT_TOOLTIP')}
                                                    >
                                                        {i18next.t('SETTLEMENT')}
                                                    </span>
                                                </th>
                                                <th className="text-right">{i18next.t('DIFFERENCE')}</th>
                                            </React.Fragment>
                                        }
                                        <th className="text-right">{i18next.t('CURRENCY_SYMBOL')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.items.map((item, i) =>
                                        <React.Fragment key={i} >
                                            <tr onClick={() => this.onRowClickOpenAgent(this.props.index, this.props.subIndex, i)}
                                                className={`${this.isRowAgentOpened(this.props.index, this.props.subIndex, i) ? 'active' : null} row-hover`}
                                            >
                                                <td>{item.contract || '-'}</td>
                                                <td className="text-right">
                                                    <Currency amount={item.quantity} symbol={false} />
                                                </td>
                                                {
                                                    this.props.isAsset ?
                                                        <React.Fragment>
                                                            <td className="text-right">
                                                                <Currency amount={item.amount / item.quantity} symbol={false} />
                                                            </td>
                                                            <td className="text-right">
                                                                <Currency amount={item.amount} symbol={false} />
                                                            </td>
                                                        </React.Fragment> :
                                                        <React.Fragment>
                                                            <td className="text-right">
                                                                <Currency amount={item.amount} symbol={false} />
                                                            </td>
                                                            <td className="text-right">
                                                                <Currency amount={item.settlement} symbol={false} />
                                                            </td>
                                                            <td className="text-right">
                                                                <Currency amount={item.dailySettlement} symbol={false} />
                                                            </td>
                                                        </React.Fragment>
                                                }
                                                <td className="text-right">
                                                    {UtilsService.translateCurrencySymbol(item.currencySymbol)}
                                                </td>
                                            </tr>
                                            {this.isRowAgentOpened(this.props.index, this.props.subIndex, i) ?
                                                item.agents.map((agent, j) =>
                                                    <tr className="row-details font-size-11" key={j}>
                                                        <td>
                                                            <div>
                                                                <strong>{i18next.t('ACCOUNT')}: </strong>
                                                                <span>
                                                                    <span >{agent.accountCode}</span>
                                                                    <span>{!!agent.accountCode ? ' - ' : ''} {agent.account || '-'}</span>
                                                                </span>
                                                            </div> 
                                                            <div>
                                                                <strong>{i18next.t('AGENT')}: </strong>
                                                                <span>{agent.agent || '-'}</span>
                                                            </div>
                                                            {this.props.isAsset ?
                                                                <div>
                                                                    <strong>{i18next.t('PURPOSE')}: </strong>
                                                                    <span>{agent.purpose || '-'}</span>
                                                                </div> : null
                                                            }
                                                            {agent.counterpartAccount ?
                                                                <div>
                                                                    <strong>{i18next.t('COUNTERPARTACCOUNT')}: </strong>
                                                                    <span >{agent.counterpartAccountCode}</span>
                                                                    <span>{!!agent.counterpartAccountCode ? ' - ' : ''} {agent.counterpartAccount || '-'}</span>
                                                                </div> : null
                                                            }
                                                        </td>
                                                        <td className="text-right" style={this.isVisible(item.agents)}>
                                                            <Currency amount={agent.quantity} symbol={false} />
                                                        </td>
                                                        {
                                                            this.props.isAsset ?
                                                                <React.Fragment>
                                                                    <td className="text-right" style={this.isVisible(item.agents)}>
                                                                        <Currency amount={agent.amount / agent.quantity} symbol={false} />
                                                                    </td>
                                                                    <td className="text-right" style={this.isVisible(item.agents)}>
                                                                        <Currency amount={agent.amount} symbol={false} />
                                                                    </td>
                                                                </React.Fragment> :
                                                                <React.Fragment>
                                                                    <td className="text-right" style={this.isVisible(item.agents)}>
                                                                        <Currency amount={agent.settlement} symbol={false} />
                                                                    </td>
                                                                    <td className="text-right" style={this.isVisible(item.agents)}>
                                                                        <Currency amount={agent.amount} symbol={false} />
                                                                    </td>
                                                                    <td className="text-right" style={this.isVisible(item.agents)}>
                                                                        <Currency amount={agent.dailySettlement} symbol={false} />
                                                                    </td>
                                                                </React.Fragment>
                                                        }
                                                        <td className="text-right" style={this.isVisible(item.agents)}>
                                                            {UtilsService.translateCurrencySymbol(item.currencySymbol)}
                                                        </td>
                                                    </tr>
                                                ) :
                                                null
                                            }
                                        </React.Fragment>
                                    )}
                                    <tr className="border-dark" style={{ height: '40px', borderTop: '2px solid' }}>
                                        <td colSpan="1" className="text-bold">
                                            {i18next.t(this.props.isAsset ? 'BALANCE' : 'TOTAL_DIFFERENCE')}:
                                            </td>
                                        <td colSpan="99" className="text-right">
                                            <BalanceTotal
                                                totals={this.props.totals} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {!this.props.items.length ?
                            <NoData msg={i18next.t('NO_RESULTS')} /> :
                            null
                        }
                    </CardBody>
                </Collapse>
           
                <Tooltip id="price-tooltip" />
                <Tooltip id="valued-active-tooltip" />
                <Tooltip id="difference-price-tooltip" />
                <Tooltip id="difference-settlement-tooltip" />
            </Card>
        );
    }
}