import React from 'react';
import i18next from 'i18next';
import { Card, CardBody, CardHeader, Button, Collapse } from 'reactstrap';
import UtilsService from './../../services/utils/utils.service';
import NoData from '../shared/noData';
import { If } from 'react-if';
import Currency from './../shared/currency';
import { BalanceTotal } from './holdings';
import { Tooltip } from 'react-tooltip'

const TableHoldings = ({ ...props }) => {
    return (
        <React.Fragment>
            <strong className={`table-holdings-title ${props.isAsset ? 'is-asset' : null}`}>
                {i18next.t(props.titleKey)}
            </strong>
            <div className="table-responsive" style={{ marginBottom: '-1rem' }}>
                <table className="table table-sm border">
                    <thead>
                        <tr className="text-muted">
                            <th style={{ width: '43%' }}>{i18next.t('INSTRUMENT')}</th>
                            {
                                props.isAsset ?
                                    <React.Fragment>
                                        <th>{i18next.t('PURPOSE')}</th>
                                        <th className="text-right">
                                        <span >{i18next.t('QUANTITY')}</span>
                                        </th>
                                        <th className="text-right">
                                            <span 
                                                data-tooltip-id="price-tooltip"
                                                data-tooltip-content={i18next.t('PRICE_TOOLTIP')}
                                            >
                                                {i18next.t('PRICE')}
                                            </span>
                                        </th>
                                        <th className="text-right">
                                            <span>{i18next.t('VALUED_ACTIVE')}</span>
                                        </th>
                                        <th className="text-right">{i18next.t('CURRENCY_SYMBOL')}</th>
                                    </React.Fragment> :
                                    <React.Fragment>
                                        <th className="text-right">{i18next.t('QUANTITY')}</th>
                                        <th className="text-right">
                                            <span 
                                                data-tooltip-id="price2-tooltip"
                                                data-tooltip-content={i18next.t('PRICE_TOOLTIP')}
                                            >
                                                {i18next.t('PRICE')}
                                            </span>
                                        </th>
                                        <th className="text-right">
                                            <span 
                                                data-tooltip-id="difference-settlement-tooltip"
                                                data-tooltip-content={i18next.t('DIFFERENCE_SETTLEMENT_TOOLTIP')}
                                            >
                                                {i18next.t('SETTLEMENT')}
                                            </span>
                                        </th>
                                        <th className="text-right">{i18next.t('DIFFERENCE')}</th>
                                        <th className="text-right">{i18next.t('CURRENCY_SYMBOL')}</th>
                                    </React.Fragment>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {props.items.map((item, i) =>
                            <tr key={i}>
                                <td>{item.contract || '-'}</td>
                                {
                                    props.isAsset ?
                                        <React.Fragment>
                                            <td>{item.purpose || '-'}</td>
                                            <td className="text-right">
                                                <Currency amount={item.quantity} symbol={false} />
                                            </td>
                                            <td className="text-right">
                                                <Currency amount={item.amount / item.quantity} symbol={false} />
                                            </td>
                                            <td className="text-right">
                                                <Currency amount={item.amount} symbol={false} />
                                            </td>
                                            <td className="text-right">
                                                {UtilsService.translateCurrencySymbol(item.currencySymbol)}
                                            </td>
                                        </React.Fragment> :
                                        <React.Fragment>
                                            <td className="text-right">
                                                <Currency amount={item.quantity} symbol={false} />
                                            </td>
                                            <td className="text-right">
                                                <Currency amount={item.amount} symbol={false} />
                                            </td>
                                            <td className="text-right">
                                                <Currency amount={item.settlement} symbol={false} />
                                            </td>
                                            <td className="text-right">
                                                <Currency amount={item.dailySettlement} symbol={false} />
                                            </td>
                                            <td className="text-right">
                                                {UtilsService.translateCurrencySymbol(item.currencySymbol)}
                                            </td>
                                        </React.Fragment>
                                }
                            </tr>
                        )}
                        <tr className="border-dark" style={{ height: '40px', borderTop: '2px solid' }}>
                            <td colSpan="1" className="text-bold">
                                {i18next.t(props.isAsset ? 'BALANCE' : 'TOTAL_DIFFERENCE')}:
                            </td>
                            <td colSpan="99" className="text-right">
                                <BalanceTotal
                                    totals={props.totals} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <Tooltip id="price-tooltip" />
            <Tooltip id="price2-tooltip" />
            <Tooltip id="difference-settlement-tooltip" />
        </React.Fragment >
    );
}

export default class HoldingStock extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            openedIndexes: []
        };
    }

    componentDidMount() { }

    toggleCollapse = (index1, index2) => {
        let index = `${index1}${index2}`;

        let openedIndexes = this.state.openedIndexes;
        let k = openedIndexes.indexOf(index);
        if (k < 0) {
            openedIndexes.push(index);
        } else {
            openedIndexes.splice(k, 1);
        }

        this.setState({
            openedIndexes: openedIndexes
        });
    }

    isCollapseOpen(index1, index2) {
        let index = `${index1}${index2}`;
        return this.state.openedIndexes.indexOf(index) >= 0;
    }

    /**
     * componentDidUpdate
     */
    componentDidUpdate(oldProps) {
        if(
            oldProps.data !== this.props.data ||
            oldProps.filter !== this.props.filter ||
            oldProps.flags !== this.props.flags
        ) {
            this.forceUpdate();
        }
    }

    render() {
        let { data, filter, flags } = this.props;

        return (
            <div>
                {!!data.agentStock.length ?
                    <React.Fragment>
                        {data.agentStock.map((as, index1) =>
                            <React.Fragment key={index1}>
                                <h6 className="main-title">{as.agent || '-'}</h6>
                                {as.accounts.map((account, index2) =>
                                    <Card key={index2} className="mb-3">
                                        <CardHeader
                                            className="cursor-pointer py-1 d-flex"
                                            style={{ minHeight: '37px' }}
                                            onClick={() => this.toggleCollapse(index1, index2)}
                                        >
                                            <strong className="flex-grow-1 align-self-center">{account.accountCode} - {account.account}</strong>
                                            <div>
                                                <div className="d-inline-block align-middle mr-4 font-size-11">

                                                    <div className={!account.assetsTotals || !account.assetsTotals.length ? 'd-none' : 'd-flex align-items-baseline'}>
                                                        <strong className="d-inline-block text-right" style={{ width: '105px' }}>
                                                            {i18next.t('TOTAL_VALUES')}:
                                                        </strong>
                                                        <BalanceTotal
                                                            className="flex-grow-1"
                                                            totals={account.assetsTotals} />
                                                    </div>

                                                    <div className={!account.futureAndOptionsTotals || !account.futureAndOptionsTotals.length ? 'd-none' : 'd-flex align-items-baseline mt-2'}>
                                                        <strong className="d-inline-block text-right" style={{ width: '105px' }}>
                                                            {i18next.t('TOTAL_FUTURES')}:
                                                        </strong>
                                                        <BalanceTotal
                                                            className="flex-grow-1"
                                                            totals={account.futureAndOptionsTotals} />

                                                    </div>

                                                </div>
                                                <Button className="m-0" color="link" size="sm">
                                                    <i className={`fa fa-chevron-${this.isCollapseOpen(index1, index2) ? 'up' : 'down'}`}></i>
                                                </Button>
                                            </div>
                                        </CardHeader>
                                        <Collapse isOpen={this.isCollapseOpen(index1, index2)}>
                                            <CardBody className="p-0">

                                                {
                                                    account.assets.length ?
                                                        <TableHoldings items={account.assets}
                                                            totals={account.assetsTotals}
                                                            isAsset={true}
                                                            titleKey={'MY_INVESTMENTS'} /> :
                                                        null
                                                }

                                                {
                                                    account.futuresAndOptions.length ?
                                                        <TableHoldings items={account.futuresAndOptions}
                                                            totals={account.futureAndOptionsTotals}
                                                            isAsset={false}
                                                            titleKey={'TOTAL_FUTURES'} /> :
                                                        null
                                                }

                                                {
                                                    !account.assets.length && !account.futuresAndOptions.length ?
                                                        <NoData msg={i18next.t('NO_RESULTS')} /> :
                                                        null
                                                }

                                            </CardBody>
                                        </Collapse>
                                    </Card>
                                )}
                                {
                                    !as.accounts.length ?
                                        <NoData msg={i18next.t('NO_RESULTS')} /> :
                                        null
                                }
                            </React.Fragment>
                        )}
                    </React.Fragment>
                    : null
                }
                <If condition={!flags.isLoading && !data.agentStock.length && !flags.showBackOfficeError}>
                    <NoData msg={`${i18next.t('NO_DATA_WITH_DATE')}: ${UtilsService.dateFormat(filter.date)}`} />
                </If>
            </div>
        );
    }
}